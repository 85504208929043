<template>
  <section>
    <div class="row" v-if="!isError">
      <div class="col-md-7 col-lg-12 col-xxl-7">
        <div>
          <Tour
            :pnr="this.pnr"
            :title="this.details.tour.productTitle"
            :summary="this.details.tour.productSummary"
            :image="this.details.tour.productImage"
            :departure-date-time="this.details.tour.departureDateTime"
            :pax="this.details.tour.pax"
            :product-id="details.tour.productId"
          ></Tour>
        </div>
        <div>
          <BookingList :bookings="details.bookings"></BookingList>
        </div>
      </div>
      <div class="col-md-5 col-lg-12 col-xxl-5">
        <div class="row">
          <div class="col-md-12">
            <TransportCompany
              :pnr="this.pnr"
              :company-name="this.details.transportation.company"
              @change="get"
            />
          </div>
          <div class="col-6 col-md-6">
            <Driver
              :pnr="this.pnr"
              :driver="this.details.transportation.driver"
              @update="updateDriver"
            ></Driver>
          </div>
          <div class="col-6 col-md-6">
            <Vehicle
              :pnr="this.pnr"
              :vehicle="this.details.transportation.vehicle"
              @update="updateVehicle"
            ></Vehicle>
          </div>
        </div>

        <div class="separator separator-dashed gutter-b"></div>
        <div class="row">
          <div class="col-md-12">
            <QuotationWrapper></QuotationWrapper>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-12">
        <PassengerList></PassengerList>
      </div>
    </div>
  </section>
</template>

<script>
import ApiService from '@/core/services/api.service';
import Tour from "@/view/pages/operation/components/details/Tour";
import TransportCompany from "@/view/pages/operation/components/details/TransportCompany";
import Driver from "@/view/pages/operation/components/details/Driver";
import Vehicle from "@/view/pages/operation/components/details/Vehicle";
import BookingList from "@/view/pages/operation/components/details/bookings/BookingList";
import PassengerList from "@/view/pages/operation/components/details/passenger-list/PassengerList";
import QuotationWrapper from "@/view/pages/operation/components/details/quotation/QuotationWrapper.vue";

export default {
  components: {
    Tour,
    TransportCompany,
    Driver,
    Vehicle,
    PassengerList,
    BookingList,
    QuotationWrapper
  },
  data() {
    return {
      pnr: this.$route.params.pnr,
      isLoading: false,
      isError: false,
      details: null
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      ApiService.get(`operation/get-details.req.php?pnr=${this.pnr}`)
      .then(({ data }) => {
        this.details = data.details;
        this.isError = false;
      })
      .catch(({ response }) => {
        this.isError = true;
        alert(response.data.errorMessage)
      });
    },

    updateDriver(driver) {
      this.details.transportation.driver = driver;
    },
    updateVehicle(vehicle) {
      this.details.transportation.vehicle = vehicle;
    },
    updateKilometersSpent(km) {
      this.details.quotation.kilometersSpent = km;
    },
    updateFuelSpent(fuelPrice) {
      this.details.quotation.fuelSpentPrice = fuelPrice;
    }
  }
}
</script>

<style>
</style>