var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"pl-0"},[_c('td',{staticClass:"pl-0"},[_c('div',{staticClass:"d-flex"},[(!_vm.missingParticipantCount)?_c('span',{staticClass:"svg-icon svg-icon-lg pr-2 svg-icon-success"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Code/Done-circle.svg"}})],1):_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",modifiers:{"hover":true,"right":true}}],staticClass:"svg-icon svg-icon-lg pr-2 svg-icon-warning",attrs:{"title":"Yolcu bilgisi eksik"}},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Code/Warning-1-circle.svg"}})],1),_c('div',[_c('router-link',{attrs:{"to":{
            name: 'reservation-details',
            params: { id: _vm.reservationId, reservationNumber: _vm.reservationNo }
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
return [_c('a',{staticClass:"\n            text-dark\n            font-weight-bolder\n            text-hover-primary\n            mb-1\n            font-size-lg\n          ",attrs:{"href":href}},[_vm._v(_vm._s(_vm.bookedBy))])]}}])}),_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v("ZNF-"+_vm._s(_vm.reservationNo))])],1)])]),_c('td',[_c('span',{staticClass:"text-muted font-weight-bolder"},[_vm._v(_vm._s(_vm.phone))])]),_c('td',[_c('span',{staticClass:"text-muted font-weight-bold d-block"},[_vm._v(_vm._s(_vm.participantsText))])]),_c('td',[_c('span',{staticClass:"text-success font-weight-bold d-block"},[_vm._v(_vm._s(_vm.price))])]),_c('td',{staticClass:"text-right"},[_c('router-link',{attrs:{"to":{
        name: 'reservation-details',
        params: { id: _vm.reservationId, reservationNumber: _vm.reservationNo }
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
return [_c('a',{staticClass:"btn btn-icon btn-light btn-sm",attrs:{"href":href}},[_c('span',{staticClass:"svg-icon svg-icon-md svg-icon-danger"},[_c('inline-svg',{attrs:{"src":"media/svg/icons/Navigation/Arrow-right.svg"}})],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }